import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

const HeroImage = (props) => (
  <div>
    <Image className="imageWidth" field={props.fields.heroImage} /><br /> <br />
    <div className="headerText"><Text field={props.fields.heading} /></div>
  </div>
);

export default HeroImage;
