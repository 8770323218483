import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const FirstSuguaeComponent = (props) => (
  <div>
    <p>FirstSuguaeComponent Component</p>
    <Text field={props.fields.heading} /> <br />
    <Text field={props.fields.description} />    
  </div>
);

export default FirstSuguaeComponent;
